import React from "react";
import CustomerCare from "../../components/CustomerCare"
import {graphql} from "gatsby";
import Layout from "../../components/Layout";
import {connect} from "react-redux";
import SEO from "../../components/SEO";

const ConciergeServices = (props) => {
    return (
        <Layout>
            <SEO {...(props?.data?.seo?.frontmatter || {})}/>
            <CustomerCare {...props} fields={props?.data}/>
        </Layout>
    )
};
const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, null)(ConciergeServices);

export const query = graphql`
fragment ConciergeServiceFragment on MarkdownRemark {
     frontmatter {
      title
      img{
        publicURL
      }
      description
      contentList{
        title
        description
        img{
            publicURL
        }
      }
    }
  }
  query ConciergePageQuery {
      seo: markdownRemark(frontmatter: {templateKey: {eq: "concierge-seo"}}) {
        frontmatter {
            title
            description
        }
  }
  enConciergeServices: markdownRemark(frontmatter: {templateKey: {eq: "en-concierge-services"}}) {
    ...ConciergeServiceFragment
  }
  deConciergeServices: markdownRemark(frontmatter: {templateKey: {eq: "de-concierge-services"}}) {
    ...ConciergeServiceFragment
  }
  esConciergeServices: markdownRemark(frontmatter: {templateKey: {eq: "es-concierge-services"}}) {
    ...ConciergeServiceFragment
  }
  frConciergeServices: markdownRemark(frontmatter: {templateKey: {eq: "fr-concierge-services"}}) {
    ...ConciergeServiceFragment
  }
  heConciergeServices: markdownRemark(frontmatter: {templateKey: {eq: "he-concierge-services"}}) {
    ...ConciergeServiceFragment
  }
  itConciergeServices: markdownRemark(frontmatter: {templateKey: {eq: "it-concierge-services"}}) {
    ...ConciergeServiceFragment
  }
  jaConciergeServices: markdownRemark(frontmatter: {templateKey: {eq: "ja-concierge-services"}}) {
    ...ConciergeServiceFragment
  }
  zhConciergeServices: markdownRemark(frontmatter: {templateKey: {eq: "zh-concierge-services"}}) {
    ...ConciergeServiceFragment
  }
 }
`;